import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Table } from 'reactstrap';
import { ConfigInterface } from 'types/interfaces/global';

interface LinksProps {
    config: ConfigInterface[];
}

export const Links = ({ config }: LinksProps) => (
    <>
        <div className="content">
            <Container fluid="true">
                <Row className="mt-6">
                    {config.map(config => (
                        <Col key={config.title + Date.now()} lg={config.key === 'heading' ? '12' : '6'}>
                            <Card className="col-md-12">
                                <CardHeader>
                                    <CardTitle tag={config.key === 'heading' ? 'h3' : 'h4'}>{config.title}</CardTitle>
                                </CardHeader>
                                {config.key !== 'heading' ? (
                                    <CardBody className="table-full-width">
                                        <Table responsive>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th> </th>
                                                    <th>ENV</th>
                                                    <th>URL</th>
                                                    <th>SERVER</th>
                                                    <th>VERSION</th>
                                                    <th>DATE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {config.links.map(link => (
                                                    <tr key={link.url + Date.now() + Math.random()}>
                                                        <td>{link.microService}</td>
                                                        <td>{link.env}</td>
                                                        <td>
                                                            <a href={link.url} rel="noreferrer" target="_blank">
                                                                {link.url}
                                                            </a>
                                                        </td>
                                                        <td>{link.server}</td>
                                                        <td>{link.version}</td>
                                                        <td>{link.date}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                ) : null}
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    </>
);
