import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'App';

ReactDOM.render(<App />, document.getElementById('root'));

// background: rgb(32, 33, 36)

// No internet
// Try:

// Checking the network cables, modem, and router
// Reconnecting to Wi-Fi
// ERR_INTERNET_DISCONNECTED

// <div id="main-message" jstcache="0">
//         <h1 jstcache="0">
//           <span jsselect="heading" jsvalues=".innerHTML:msg" jstcache="9">No internet</span>
//           <a id="error-information-button" class="hidden" onclick="toggleErrorInformationPopup();" jstcache="0"></a>
//         </h1>
//         <p jsselect="summary" jsvalues=".innerHTML:msg" jstcache="1">No internet</p>
//         <!--The suggestion list and error code are normally presented inline,
//           in which case error-information-popup-* divs have no effect. When
//           error-information-popup-container has the use-popup-container class, this
//           information is provided in a popup instead.-->
//         <div id="error-information-popup-container" jstcache="0">
//           <div id="error-information-popup" jstcache="0">
//             <div id="error-information-popup-box" jstcache="0">
//               <div id="error-information-popup-content" jstcache="0">
//                 <div id="suggestions-list" style="" jsdisplay="(suggestionsSummaryList &amp;&amp; suggestionsSummaryList.length)" jstcache="16">
//                   <p jsvalues=".innerHTML:suggestionsSummaryListHeader" jstcache="18">Try:</p>
//                   <ul jsvalues=".className:suggestionsSummaryList.length == 1 ? 'single-suggestion' : ''" jstcache="19" class="">
//                     <li jsselect="suggestionsSummaryList" jsvalues=".innerHTML:summary" jstcache="21" jsinstance="0">Checking the network cables, modem, and router</li><li jsselect="suggestionsSummaryList" jsvalues=".innerHTML:summary" jstcache="21" jsinstance="*1">Reconnecting to Wi-Fi</li>
//                   </ul>
//                 </div>
//                 <div class="error-code" jscontent="errorCode" jstcache="17">ERR_INTERNET_DISCONNECTED</div>
//                 <p id="error-information-popup-close" jstcache="0">
//                   <a class="link-button" jscontent="closeDescriptionPopup" onclick="toggleErrorInformationPopup();" jstcache="20">null</a>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div id="download-links-wrapper" class="hidden" jstcache="0">
//           <div id="download-link-wrapper" jstcache="0">
//             <a id="download-link" class="link-button" onclick="downloadButtonClick()" jsselect="downloadButton" jscontent="msg" jsvalues=".disabledText:disabledMsg" jstcache="6" style="display: none;">
//             </a>
//           </div>
//           <div id="download-link-clicked-wrapper" class="hidden" jstcache="0">
//             <div id="download-link-clicked" class="link-button" jsselect="downloadButton" jscontent="disabledMsg" jstcache="11" style="display: none;">
//             </div>
//           </div>
//         </div>
//         <div id="save-page-for-later-button" class="hidden" jstcache="0">
//           <a class="link-button" onclick="savePageLaterClick()" jsselect="savePageLater" jscontent="savePageMsg" jstcache="10" style="display: none;">
//           </a>
//         </div>
//         <div id="cancel-save-page-button" class="hidden" onclick="cancelSavePageClick()" jsselect="savePageLater" jsvalues=".innerHTML:cancelMsg" jstcache="4" style="display: none;">
//         </div>
//         <div id="offline-content-list" class="list-hidden" hidden="" jstcache="0">
//           <div id="offline-content-list-visibility-card" onclick="toggleOfflineContentListVisibility(true)" jstcache="0">
//             <div id="offline-content-list-title" jsselect="offlineContentList" jscontent="title" jstcache="12" style="display: none;">
//             </div>
//             <div jstcache="0">
//               <div id="offline-content-list-show-text" jsselect="offlineContentList" jscontent="showText" jstcache="14" style="display: none;">
//               </div>
//               <div id="offline-content-list-hide-text" jsselect="offlineContentList" jscontent="hideText" jstcache="15" style="display: none;">
//               </div>
//             </div>
//           </div>
//           <div id="offline-content-suggestions" jstcache="0"></div>
//           <div id="offline-content-list-action" jstcache="0">
//             <a class="link-button" onclick="launchDownloadsPage()" jsselect="offlineContentList" jscontent="actionText" jstcache="13" style="display: none;">
//             </a>
//           </div>
//         </div>
//       </div>
