const config = [
    {
        title: 'Unnamed',
        key: 'heading',
        links: []
    },
    {
        title: 'Unnamed API',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: '',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://unmd.api.dev.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://unmd.api.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            }
        ]
    },
    {
        title: 'Unnamed Admin',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: '',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://unmd.admin.dev.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://unmd.admin.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            }
        ]
    },
    {
        title: 'Unnamed Site',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: '',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://unmd.site.dev.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://unmd.site.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            }
        ]
    },
    {
        title: 'Unnamed Landing',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: '',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://unmd.landing.dev.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://unmd.landing.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            }
        ]
    }
];

export default config;
