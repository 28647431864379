const config = [
    {
        title: 'Vizzy',
        key: 'heading',
        links: []
    },
    {
        title: 'Vizzy API',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://api.kiaradocs.nl/',
                version: '1.0.0',
                date: 'Feb 25th 2022'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://vizy.api.dev.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://vizy.api.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            }
        ]
    },
    {
        title: 'Vizzy Admin',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: '',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://vizy.admin.dev.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://vizy.admin.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            }
        ]
    },
    {
        title: 'Vizzy Site',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: '',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://vizy.site.dev.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://vizy.site.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            }
        ]
    }
];

export default config;
