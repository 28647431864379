const config = [
    {
        title: 'Container Radar',
        key: 'heading',
        links: []
    },
    {
        title: 'Container Radar API',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: '',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://cnrd.api.dev.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            }
        ]
    },
    {
        title: 'Container Radar Admin',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: '',
                version: '0.0.0',
                date: ''
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://cnrd.admin.dev.incodewetrust.online/',
                version: '0.0.0',
                date: ''
            }
        ]
    }
];

export default config;
