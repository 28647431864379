import { Project } from 'types/types';

export const logos: { [key in Project]: string[] } = {
    YWB: ['wom', 'yeay', 'bullz'],
    Vizzy: ['vizzy'],
    TMTV: ['tmtv'],
    Container: ['container'],
    Zigifts: ['zigifts'],
    UNMD: ['unmd']
};
