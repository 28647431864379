import React from 'react';
import { errorLink, getProjectLink, projects } from 'routes';
import { Project } from 'types/types';
import { ENV_PASSWORD, ENV_USERNAME, localStorageKey } from 'constants/services';
import { getLSData, setLSData } from 'utils/service';
import { Redirect } from 'react-router-dom';

const questions = [
    'What is your favorite TV show?',
    'What is the Pi?',
    'In "How I met Your Mother" what was the name of the "Mother"?',
    'Was Rachel and Ross on a Break?',
    'In "Brooklin 99" who is the main character?'
];

interface Credentials {
    username: string;
    password: string;
}

//@ts-ignore
export let credentialsData: {
    [key in Project]: Credentials;
} = {};

projects.forEach(project => {
    credentialsData[project] = {
        username: ENV_USERNAME(project),
        password: ENV_PASSWORD(project)
    };
});

const allUsers = (Object.keys(credentialsData) as Project[]).map(project => credentialsData[project].username);

const hasUser = (userName: string | null) => allUsers.some(user => user === userName);

const getProjectByUser = (userName: string | null) => {
    let projectName: Project | undefined = undefined;

    (Object.keys(credentialsData) as Project[]).forEach(project => {
        if (credentialsData[project].username === userName) {
            projectName = project;
        }
    });

    return projectName;
};

let hasError = false;

export const Login = () => {
    const credentials = getLSData(localStorageKey);

    const username = prompt('Who are you?');

    if (hasUser(username)) {
        const project = getProjectByUser(username) as Project | undefined;
        const password = prompt(questions[Math.floor(Math.random() * 5)]);

        if (project && credentialsData[project].password === password) {
            setLSData(localStorageKey, { ...credentials, [project]: { username, password } });

            return <Redirect to={getProjectLink(project)} />;
        } else {
            hasError = true;
        }
    } else {
        hasError = true;
    }

    if (hasError) {
        return <Redirect to={errorLink} />;
    }

    return <div className="content" />;
};
