import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'assets/css/nucleo-icons.css';
import 'react-notification-alert/dist/animate.css';
import 'assets/scss/black-dashboard-pro-react.scss?v=1.2.0';
import 'assets/demo/demo.css';
import 'assets/css/custom.css';
import { Version } from 'components/Version';
import { ErrorComponent } from 'pages/Error';
import { applicationRoutes, errorLink, loginLink } from 'routes';
import { PrivateRoute } from 'routes/PrivateRoute';
import { Project } from 'types/types';
import { Login } from 'pages/Login';

export const App = () => (
    <>
        <Version />
        <BrowserRouter>
            <Switch>
                {(Object.keys(applicationRoutes) as Project[]).map(project => (
                    <PrivateRoute key={project} path={applicationRoutes[project]} project={project} />
                ))}
                <Route component={Login} path={loginLink} />
                <Route component={ErrorComponent} path={[errorLink, '*']} />
            </Switch>
        </BrowserRouter>
    </>
);
