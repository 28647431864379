import React from 'react';
import { RouteProps } from 'react-router';
import { errorLink, loginLink } from 'routes';
import { Redirect, Route } from 'react-router-dom';
import { Links } from 'pages/Links';
import { configs } from 'constants/allLinks';
import { Project } from 'types/types';
import { getLSData } from 'utils/service';
import { credentialsData } from 'pages/Login';
import { localStorageKey } from 'constants/services';
import { Logos } from 'components/Logos';

interface PrivateRouteProps extends Pick<RouteProps, 'path'> {
    project: Project;
}

export const PrivateRoute = ({ project, path }: PrivateRouteProps) => {
    const config = configs[project];

    if (!config) {
        return <Redirect to={errorLink} />;
    }

    const credentials = getLSData(localStorageKey);

    if (
        credentials[project]?.username !== credentialsData[project].username ||
        credentials[project]?.password !== credentialsData[project].password
    ) {
        return <Redirect to={loginLink} />;
    }

    return (
        <Route
            component={() => (
                <>
                    <Logos project={project} />
                    <Links config={configs[project]} />
                </>
            )}
            path={path}
        />
    );
};
