const config = [
    {
        title: 'YEAY',
        key: 'heading',
        links: []
    },
    {
        title: 'YEAY CAMPAIGN MANAGER',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://cm.yeay.com/',
                version: '0.12.1',
                date: 'Aug 22nd 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://yeay.cm.prod-stage.su/',
                version: '0.12.1',
                date: 'Aug 22nd 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://yeay.cm.dev-stage.su/',
                version: '0.12.1',
                date: 'Aug 22nd 2023'
            }
        ]
    },
    {
        title: 'YEAY CAMPAIGN MANAGER ADMIN SYSTEM',
        key: 'service',
        links: [
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://yeay.cm.admin.prod-stage.su/',
                version: '0.0.0',
                date: 'Sep 14th 2022'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://yeay.cm.admin.dev-stage.su/',
                version: '0.0.0',
                date: 'Sep 14th 2022'
            }
        ]
    },
    {
        title: 'YEAY ADMIN SYSTEM',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://afjmjt.yeay.com/',
                version: '4.18.3',
                date: 'Aug 25th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://yeay.admin.prod-stage.su/',
                version: '4.18.3',
                date: 'Aug 25th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://yeay.admin.dev-stage.su/',
                version: '4.18.3',
                date: 'Aug 25th 2023'
            }
        ]
    },
    {
        title: 'YEAY PLAYER',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://player.yeay.com/',
                version: '1.0.1',
                date: 'Aug 15th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://yeay.player.new.prod-stage.su/',
                version: '1.0.1',
                date: 'Aug 15th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://yeay.player.new.dev-stage.su/',
                version: '1.0.1',
                date: 'Aug 15th 2023'
            }
        ]
    },
    {
        title: 'YEAY WEB RECORDER',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://creator.yeay.com/',
                version: '1.4.3',
                date: 'Sep 01st 2022'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://yeay.creator.dev-stage.su/',
                version: '1.4.3',
                date: 'Sep 01st 2022'
            }
        ]
    }
];

export default config;
