const config = [
    {
        title: 'BULLZ',
        key: 'heading',
        links: []
    },
    {
        title: 'BULLZ CAMPAIGN MANAGER',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://cm.bullz.com/',
                version: '1.12.4',
                date: 'Aug 10th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://bullz.cm.prod-stage.su/',
                version: '1.12.4',
                date: 'Aug 10th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://bullz.cm.dev-stage.su/',
                version: '1.12.4',
                date: 'Aug 10th 2023'
            }
        ]
    },
    {
        title: 'BULLZ CAMPAIGN MANAGER ADMIN SYSTEM',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://ycsbndip.bullz.com/',
                version: '1.7.8',
                date: 'Jul 4th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://bullz.cm.admin.prod-stage.su/',
                version: '1.7.8',
                date: 'Jul 4th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://bullz.cm.admin.dev-stage.su/',
                version: '1.7.8',
                date: 'Jul 4th 2023'
            }
        ]
    },
    {
        title: 'BULLZ ADMIN SYSTEM',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://bnasatap.bullz.com/',
                version: '2.7.4',
                date: 'Jul 19th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://bullz.admin.prod-stage.su/',
                version: '2.7.4',
                date: 'Jul 19th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://bullz.admin.dev-stage.su/',
                version: '2.7.4',
                date: 'Jul 19th 2023'
            }
        ]
    },
    {
        title: 'BULLZ PLAYER',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://player.bullz.com/',
                version: '1.16.1',
                date: 'Aug 1st 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://bullz.player.prod-stage.su/',
                version: '1.16.1',
                date: 'Aug 1st 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://bullz.player.dev-stage.su/',
                version: '1.16.1',
                date: 'Aug 1st 2023'
            }
        ]
    },
    {
        title: 'BULLZ WEB RECORDER',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://creator.bullz.com/',
                version: '1.5.0',
                date: 'Sep 06th 2022'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://bullz.creator.dev-stage.su/',
                version: '1.5.0',
                date: 'Sep 06th 2022'
            }
        ]
    }
];

export default config;
