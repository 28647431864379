//
const config = [
    {
        title: 'WOM',
        key: 'heading',
        links: []
    },
    {
        title: 'WOM ADMIN SYSTEM',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://myobfoad.womprotocol.io/',
                version: '3.2.1',
                date: 'Aug 15th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://wom.wasy.prod-stage.su/',
                version: '3.2.1',
                date: 'Aug 15th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://wom.wasy.dev-stage.su/',
                version: '3.2.1',
                date: 'Aug 15th 2023'
            }
        ]
    },
    {
        title: 'WOM CAMPAIGN MANAGER',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://cm.womprotocol.io/',
                version: '2.7.1',
                date: 'Aug 23rd 2022'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'https://wom.cm.prod-stage.su/',
                version: '2.7.1',
                date: 'Aug 23rd 2022'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://wom.cm.dev-stage.su/',
                version: '2.7.1',
                date: 'Aug 23rd 2022'
            }
        ]
    },
    {
        title: 'WOM PRODUCT VIEWER',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'http://viewer.womprotocol.io/',
                version: '1.3.10',
                date: 'Jul 4th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://wom.product.viewer.dev-stage.su/',
                version: '1.3.10',
                date: 'Jul 4th 2023'
            }
        ]
    },
    {
        title: 'WOM CHANNEL VIEWER',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: 'https://channel-viewer.womprotocol.io/',
                version: '1.4.11',
                date: 'Jul 4th 2023'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'https://wom.channel.viewer.dev-stage.su/',
                version: '1.4.11',
                date: 'Jul 4th 2023'
            }
        ]
    },

    {
        title: 'WOM CAMPAIGN MANAGER NEW DESIGN',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: '',
                env: 'PROD',
                url: '',
                version: '0.0.0',
                date: 'Oct 03rd 2022'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'PROD',
                url: 'http://wom.cm.newdesign.prod-stage.su/',
                version: '0.0.0',
                date: 'Oct 03rd 2022'
            },
            {
                server: 'ICWT',
                microService: '',
                env: 'DEV',
                url: 'http://wom.cm.newdesign.dev-stage.su/',
                version: '0.0.0',
                date: 'Oct 03rd 2022'
            }
        ]
    }
];

export default config;
