import { Project } from 'types/types';

export const appVersion = process.env.REACT_APP_VERSION as string;
console.log('process.env', process.env);
export const ENV_USERNAME = (project: Project) =>
    process.env['REACT_APP_ENV_' + project.toUpperCase() + '_USERNAME'] as string;
export const ENV_PASSWORD = (project: Project) =>
    process.env['REACT_APP_ENV_' + project.toUpperCase() + '_PASSWORD'] as string;

export const localStorageKey = 'credentials';
