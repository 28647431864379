import React from 'react';
import { refreshPage } from 'utils/service';

export const ErrorComponent = () => (
    <>
        <div className="main-message">
            <div className="main-message__content">
                <div className="inner">
                    <div className="icon icon-generic"></div>
                    <div className="inner__title">This site can’t be reached</div>
                    <div className="inner__sub-title">Check if there is a typo in the url</div>
                    <div className="inner__description">DNS_PROBE_FINISHED_NXDOMAIN</div>
                </div>
                <div className="control-buttons">
                    <button className="blue-button text-button" id="reload-button" onClick={refreshPage}>
                        Reload
                    </button>
                </div>
            </div>
        </div>
    </>
);
