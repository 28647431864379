import { localStorageKey } from 'constants/services';

export function clearLS() {
    localStorage.removeItem(localStorageKey);
}

export function refreshPage() {
    clearLS();
    window.location.reload();
}

export const getLSData = (key: string) => {
    const data = localStorage.getItem(key);

    return data ? JSON.parse(data) : {};
};

export const setLSData = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
};
