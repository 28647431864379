//
const config = [
    {
        title: 'APIs',
        key: 'heading',
        links: []
    },
    {
        title: 'YEAY APIs',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: 'SWAGGER',
                env: 'DEV',
                url: 'https://dev.api.yeay.com/docs/index.html',
                version: '1.76.4',
                date: 'Dec 21th 2022'
            },
            {
                server: 'CLIENT',
                microService: 'SWAGGER (OLD)',
                env: 'PROD',
                url: 'https://api-v1.yeay.com/docs/index.html',
                version: '1.75.1',
                date: 'Dec 21th 2022'
            },
            {
                server: 'CLIENT',
                microService: 'SWAGGER',
                env: 'PROD',
                url: 'https://api.yeay.com/docs/index.html',
                version: '1.102.0.0',
                date: 'Apr 05th 2023'
            }
        ]
    },
    {
        title: 'WOM APIs',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: 'SWAGGER',
                env: 'DEV',
                url: 'https://dev.api.womprotocol.io/docs/index.html',
                version: '1.112.10',
                date: 'Dec 21th 2022'
            },
            {
                server: 'CLIENT',
                microService: 'SWAGGER',
                env: 'PROD',
                url: 'https://api-v1.womprotocol.io/docs/index.html',
                version: '1.112.0',
                date: 'Dec 21th 2022'
            }
        ]
    },
    {
        title: 'BULLZ APIs',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: 'SWAGGER',
                env: 'DEV',
                url: 'https://bullz-dev.bullz.io/docs/index.html',
                version: '1.122.6.0',
                date: 'Apr 06th 2023'
            },
            {
                server: 'CLIENT',
                microService: 'SWAGGER',
                env: 'PROD',
                url: 'https://api-v1.bullz.com/docs/index.html',
                version: '1.122.4.0',
                date: 'Apr 06th 2023'
            }
        ]
    },
    {
        title: 'BCM APIs',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: 'SWAGGER',
                env: 'DEV',
                url: 'https://bcm-dev.bullz.io/docs/index.html',
                version: '1.73.3',
                date: 'Dec 21th 2022'
            },
            {
                server: 'CLIENT',
                microService: 'SWAGGER',
                env: 'PROD',
                url: 'https://api-bcm-v1.bullz.com/docs/index.html',
                version: '1.64.1',
                date: 'Dec 21th 2022'
            }
        ]
    },
    {
        title: 'YCM APIs',
        key: 'service',
        links: [
            {
                server: 'CLIENT',
                microService: 'SWAGGER',
                env: 'DEV',
                url: 'https://dev-ycm.womprotocol.net/docs/index.html',
                version: '0.12.5.0',
                date: 'Mar 7th 2023'
            },
            {
                server: 'CLIENT',
                microService: 'SWAGGER',
                env: 'PROD',
                url: 'https://api.cm.yeay.com/docs/index.html',
                version: '0.1.4.0',
                date: 'Mar 7th 2023'
            }
        ]
    }
];

export default config;
