import projectsApis from './projectsApis';
import projectsYeayConfig from './projectsYeay';
import projectsWomConfig from './projectsWom';
import projectsBullzConfig from './projectsBullz';
import projectsUnnamedConfig from './projectsUnnamed';
import projectsZigiftsConfig from './projectsZigifts';
import projectsVizzyConfig from './projectsVizzy';
import projectsContainerConfig from './projectsContainer';
import projectsTMTVConfig from './projectsTMTV';
import { ConfigInterface } from 'types/interfaces/global';
import { Project } from 'types/types';

export const ywbConfigs = [...projectsApis, ...projectsYeayConfig, ...projectsWomConfig, ...projectsBullzConfig];

export const configs: { [key in Project]: ConfigInterface[] } = {
    YWB: ywbConfigs,
    UNMD: projectsUnnamedConfig,
    Container: projectsContainerConfig,
    Zigifts: projectsZigiftsConfig,
    Vizzy: projectsVizzyConfig,
    TMTV: projectsTMTVConfig
};
