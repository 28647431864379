import React from 'react';
import { logos } from 'constants/allLogos';
import { Project } from 'types/types';

interface LogosProps {
    project: Project;
}

export const Logos = ({ project }: LogosProps) => (
    <div className="logo-container">
        {logos[project].map(name => (
            <img
                key={name + Date.now() + Math.random()}
                alt={name}
                className="rounded img-raised"
                src={require(`assets/logos/${name}.png`).default}
            />
        ))}
    </div>
);
