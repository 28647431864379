import { Links } from 'pages/Links';
import { Project } from 'types/types';

export type RouteStateType =
    | 'pagesCollapse'
    | 'componentsCollapse'
    | 'multiCollapse'
    | 'formsCollapse'
    | 'tablesCollapse'
    | 'mapsCollapse';

export type SidebarStatesType = {
    [key in RouteStateType]?: boolean;
};

export interface RouteInterface {
    collapse?: boolean;
    path?: string;
    name?: string;
    rtlName?: string;
    mini?: string;
    rtlMini?: string;
    icon?: string;
    state?: RouteStateType;
    component?: (props: any) => JSX.Element;
    layout?: string;
    views?: RouteInterface[];
}

export type RoutesType = RouteInterface[];

export const routes: RoutesType = [
    {
        path: '/links',
        name: 'links',
        rtlName: '',
        icon: 'tim-icons icon-chart-pie-36',
        component: Links,
        layout: '/admin'
    }
];

export const errorLink = '/error';
export const loginLink = '/login';

export const projects: Project[] = ['YWB', 'UNMD', 'Zigifts', 'Container', 'Vizzy', 'TMTV'];

export const getProjectLink = (project: Project) => '/' + project.toLowerCase() + '/links';

//@ts-ignore
export let applicationRoutes: { [key in Project]: string } = {};

projects.forEach(project => {
    applicationRoutes[project] = getProjectLink(project);
});
